//font-size
.smfont {
  font-size: 12px !important;
  font-weight: normal !important;
}
.homeheadfont {
  font-size: 42px !important;
  font-weight: 700 !important;
  line-height: 50px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    font-size: 37px !important;
    font-weight: 700 !important;
    line-height: 39px !important;
  }
  @media (max-width: 640px) {
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 35px !important;
  }
}
.headfont {
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }
  @media (max-width: 640px) {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }
}
.middlefont {
  font-size: 27px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    font-size: 25px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
  }
  @media (max-width: 640px) {
    font-size: 19px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
  }
}
.parafont {
  font-size: 16px !important;
  font-weight: normal !important;
  @media (max-width: 768px) and (min-width: 641px) {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  @media (max-width: 640px) {
    font-size: 12px !important;
    font-weight: normal !important;
  }
}
.fsize1 {
  font-size: 1px !important;
}
.fsize2 {
  font-size: 2px !important;
}
.fsize3 {
  font-size: 3px !important;
}
.fsize4 {
  font-size: 4px !important;
}
.fsize5 {
  font-size: 5px !important;
}
.fsize6 {
  font-size: 6px !important;
}
.fsize7 {
  font-size: 7px !important;
}
.fsize8 {
  font-size: 8px !important;
}
.fsize9 {
  font-size: 9px !important;
}
.fsize10 {
  font-size: 10px !important;
}
.fsize11 {
  font-size: 11px !important;
}
.fsize12 {
  font-size: 12px !important;
}
.fsize13 {
  font-size: 13px !important;
}
.fsize14 {
  font-size: 14px !important;
}
.fsize15 {
  font-size: 15px !important;
}
.fsize16 {
  font-size: 16px !important;
}
.fsize17 {
  font-size: 17px !important;
}
.fsize18 {
  font-size: 18px !important;
}
.fsize19 {
  font-size: 19px !important;
}
.fsize20 {
  font-size: 20px !important;
}
.fsize21 {
  font-size: 21px !important;
}
.fsize22 {
  font-size: 22px !important;
}
.fsize23 {
  font-size: 23px !important;
}
.fsize24 {
  font-size: 24px !important;
}
.fsize25 {
  font-size: 25px !important;
}
.fsize26 {
  font-size: 26px !important;
}
.fsize27 {
  font-size: 27px !important;
}
.fsize28 {
  font-size: 28px !important;
}
.fsize29 {
  font-size: 29px !important;
}
.fsize30 {
  font-size: 30px !important;
}
.fsize31 {
  font-size: 31px !important;
}
.fsize32 {
  font-size: 32px !important;
}
.fsize33 {
  font-size: 33px !important;
}
.fsize34 {
  font-size: 34px !important;
}
.fsize35 {
  font-size: 35px !important;
}
.fsize36 {
  font-size: 36px !important;
}
.fsize37 {
  font-size: 37px !important;
}
.fsize38 {
  font-size: 38px !important;
}
.fsize39 {
  font-size: 39px !important;
}
.fsize40 {
  font-size: 40px !important;
}
.fsize41 {
  font-size: 41px !important;
}
.fsize42 {
  font-size: 42px !important;
}
.fsize43 {
  font-size: 43px !important;
}
.fsize44 {
  font-size: 44px !important;
}
.fsize45 {
  font-size: 45px !important;
}
.fsize46 {
  font-size: 46px !important;
}
.fsize47 {
  font-size: 47px !important;
}
.fsize48 {
  font-size: 48px !important;
}
.fsize49 {
  font-size: 49px !important;
}
.fsize50 {
  font-size: 50px !important;
}

@media (max-width: 768px) and (min-width: 641px) {
  .md-fsize1 {
    font-size: 1px !important;
  }
  .md-fsize2 {
    font-size: 2px !important;
  }
  .md-fsize3 {
    font-size: 3px !important;
  }
  .md-fsize4 {
    font-size: 4px !important;
  }
  .md-fsize5 {
    font-size: 5px !important;
  }
  .md-fsize6 {
    font-size: 6px !important;
  }
  .md-fsize7 {
    font-size: 7px !important;
  }
  .md-fsize8 {
    font-size: 8px !important;
  }
  .md-fsize9 {
    font-size: 9px !important;
  }
  .md-fsize10 {
    font-size: 10px !important;
  }
  .md-fsize11 {
    font-size: 11px !important;
  }
  .md-fsize12 {
    font-size: 12px !important;
  }
  .md-fsize13 {
    font-size: 13px !important;
  }
  .md-fsize14 {
    font-size: 14px !important;
  }
  .md-fsize15 {
    font-size: 15px !important;
  }
  .md-fsize16 {
    font-size: 16px !important;
  }
  .md-fsize17 {
    font-size: 17px !important;
  }
  .md-fsize18 {
    font-size: 18px !important;
  }
  .md-fsize19 {
    font-size: 19px !important;
  }
  .md-fsize20 {
    font-size: 20px !important;
  }
  .md-fsize21 {
    font-size: 21px !important;
  }
  .md-fsize22 {
    font-size: 22px !important;
  }
  .md-fsize23 {
    font-size: 23px !important;
  }
  .md-fsize24 {
    font-size: 24px !important;
  }
  .md-fsize25 {
    font-size: 25px !important;
  }
  .md-fsize26 {
    font-size: 26px !important;
  }
  .md-fsize27 {
    font-size: 27px !important;
  }
  .md-fsize28 {
    font-size: 28px !important;
  }
  .md-fsize29 {
    font-size: 29px !important;
  }
  .md-fsize30 {
    font-size: 30px !important;
  }
  .md-fsize31 {
    font-size: 31px !important;
  }
  .md-fsize32 {
    font-size: 32px !important;
  }
  .md-fsize33 {
    font-size: 33px !important;
  }
  .md-fsize34 {
    font-size: 34px !important;
  }
  .md-fsize35 {
    font-size: 35px !important;
  }
  .md-fsize36 {
    font-size: 36px !important;
  }
  .md-fsize37 {
    font-size: 37px !important;
  }
  .md-fsize38 {
    font-size: 38px !important;
  }
  .md-fsize39 {
    font-size: 39px !important;
  }
  .md-fsize40 {
    font-size: 40px !important;
  }
  .md-fsize41 {
    font-size: 41px !important;
  }
  .md-fsize42 {
    font-size: 42px !important;
  }
  .md-fsize43 {
    font-size: 43px !important;
  }
  .md-fsize44 {
    font-size: 44px !important;
  }
  .md-fsize45 {
    font-size: 45px !important;
  }
  .md-fsize46 {
    font-size: 46px !important;
  }
  .md-fsize47 {
    font-size: 47px !important;
  }
  .md-fsize48 {
    font-size: 48px !important;
  }
  .md-fsize49 {
    font-size: 49px !important;
  }
  .md-fsize50 {
    font-size: 50px !important;
  }
}

@media (max-width: 640px) {
  .sm-fsize1 {
    font-size: 1px !important;
  }
  .sm-fsize2 {
    font-size: 2px !important;
  }
  .sm-fsize3 {
    font-size: 3px !important;
  }
  .sm-fsize4 {
    font-size: 4px !important;
  }
  .sm-fsize5 {
    font-size: 5px !important;
  }
  .sm-fsize6 {
    font-size: 6px !important;
  }
  .sm-fsize7 {
    font-size: 7px !important;
  }
  .sm-fsize8 {
    font-size: 8px !important;
  }
  .sm-fsize9 {
    font-size: 9px !important;
  }
  .sm-fsize10 {
    font-size: 10px !important;
  }
  .sm-fsize11 {
    font-size: 11px !important;
  }
  .sm-fsize12 {
    font-size: 12px !important;
  }
  .sm-fsize13 {
    font-size: 13px !important;
  }
  .sm-fsize14 {
    font-size: 14px !important;
  }
  .sm-fsize15 {
    font-size: 15px !important;
  }
  .sm-fsize16 {
    font-size: 16px !important;
  }
  .sm-fsize17 {
    font-size: 17px !important;
  }
  .sm-fsize18 {
    font-size: 18px !important;
  }
  .sm-fsize19 {
    font-size: 19px !important;
  }
  .sm-fsize20 {
    font-size: 20px !important;
  }
  .sm-fsize21 {
    font-size: 21px !important;
  }
  .sm-fsize22 {
    font-size: 22px !important;
  }
  .sm-fsize23 {
    font-size: 23px !important;
  }
  .sm-fsize24 {
    font-size: 24px !important;
  }
  .sm-fsize25 {
    font-size: 25px !important;
  }
  .sm-fsize26 {
    font-size: 26px !important;
  }
  .sm-fsize27 {
    font-size: 27px !important;
  }
  .sm-fsize28 {
    font-size: 28px !important;
  }
  .sm-fsize29 {
    font-size: 29px !important;
  }
  .sm-fsize30 {
    font-size: 30px !important;
  }
  .sm-fsize31 {
    font-size: 31px !important;
  }
  .sm-fsize32 {
    font-size: 32px !important;
  }
  .sm-fsize33 {
    font-size: 33px !important;
  }
  .sm-fsize34 {
    font-size: 34px !important;
  }
  .sm-fsize35 {
    font-size: 35px !important;
  }
  .sm-fsize36 {
    font-size: 36px !important;
  }
  .sm-fsize37 {
    font-size: 37px !important;
  }
  .sm-fsize38 {
    font-size: 38px !important;
  }
  .sm-fsize39 {
    font-size: 39px !important;
  }
  .sm-fsize40 {
    font-size: 40px !important;
  }
  .sm-fsize41 {
    font-size: 41px !important;
  }
  .sm-fsize42 {
    font-size: 42px !important;
  }
  .sm-fsize43 {
    font-size: 43px !important;
  }
  .sm-fsize44 {
    font-size: 44px !important;
  }
  .sm-fsize45 {
    font-size: 45px !important;
  }
  .sm-fsize46 {
    font-size: 46px !important;
  }
  .sm-fsize47 {
    font-size: 47px !important;
  }
  .sm-fsize48 {
    font-size: 48px !important;
  }
  .sm-fsize49 {
    font-size: 49px !important;
  }
  .sm-fsize50 {
    font-size: 50px !important;
  }
}
