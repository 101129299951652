@import "~bootstrap/scss/bootstrap";
p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin-nav {
  margin-top: 64px !important;
  @media (max-width: 640px) {
    margin-top: 59px !important;
  }
}
.border-second {
  border: 2px solid var(--qtheme-color-secondary) !important;
}
.btn-hide {
  display: none !important;
}
.bg-light-primary {
  background-color: #cdcedd21 !important;
  border-left: 2px solid var(--qtheme-color-primary) !important;
}
.bg-gray {
  background-color: gray !important;
}
//navbar
.widthsm {
  @media (max-width: 640px) {
    width: 100% !important;
  }
  @media (max-width: 768px) and (min-width: 641px) {
    width: 100% !important;
  }
}
.swiper-wrapper {
  display: flex !important;
}
.navbar-nav {
  @media (max-width: 768px) and (min-width: 641px) {
    display: block !important;
  }
  @media (max-width: 640px) {
    display: block !important;
  }
}
.underlino {
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
.underlino-blck {
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000 !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
a#nav-dropdown-dark-example {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  @media (max-width: 640px) {
    font-weight: 300 !important;
  }
}
.navbar-toggler {
  background-color: #ececec !important;
  font-size: 15px !important;
}

//opacity
.filter-b3 {
  filter: brightness(0.3) !important;
}
.filter-b5 {
  filter: brightness(0.5) !important;
}
.filter-b6 {
  filter: brightness(0.6) !important;
}
@media (max-width: 640px) {
  .he-smal {
    height: 200px !important;
  }
  .he-smal svg.recharts-surface {
    height: 190px !important;
  }
}
//home
.h-banner {
  height: 650px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 450px !important;
  }
  @media (max-width: 640px) {
    height: 500px !important;
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  margin-right: 50%;
  height: 550px;
  border-radius: 25px;
  @media (max-width: 640px) {
    height: 330px !important;
  }
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100px;
  @media (max-width: 640px) {
    height: 90px !important;
  }
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  object-fit: contain !important;
}
//bootstrap
.dropdown-menu.show {
  border-radius: 0px 0px 7px 7px !important;
  border: 1px solid #f2f2f2 !important;
  padding-left: 7px !important;
  font-size: 15px !important;
  padding-right: 7px !important;
  padding-bottom: 15px !important;
  padding-top: 10px !important;
  font-family: "Poppins", sans-serif !important;
  left: -110px !important;
  @media (max-width: 640px) {
    box-shadow: 0px 0px 0px 0px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    border: 1px solid #ececec !important;
    width: 100% !important;
    left: 0px !important;
  }
}
a#nav-dropdown-dark-example {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

//overflow
.overflow-verticle {
  overflow-x: auto !important;
}

//height
.h-100vh {
  height: 100vh !important;
}
.h-90vh {
  height: 88vh !important;
}
.zindex {
  z-index: 999999 !important;
  position: relative;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.normal-hidden {
  display: block !important;
  @media (max-width: 640px) {
    display: none !important;
  }
}
.default-hidden {
  display: none !important;
  @media (max-width: 640px) {
    display: block !important;
  }
}
@media (max-width: 640px) {
  .sidebar-overlay {
    width: 80% !important;
  }
  .mini-margin {
    margin-top: 12px !important;
  }
}
.radio {
  width: 20px !important;
  height: 20px !important;
  color: #444 !important;
  background-color: #444 !important;
}
.radio:active {
  width: 20px !important;
  height: 20px !important;
  color: #444 !important;
  background-color: #444 !important;
}
.bg-light {
  background-color: #ececec !important;
  border-radius: 50% !important;
}
.el-upload {
  width: 100% !important;
}
.el-upload-dragger {
  width: 100% !important;
}
.fade.modal.show {
  z-index: 999999999 !important;
}
.textsecondary {
  color: var(--qtheme-color-secondary) !important;
}
@media (max-width: 640px) {
  .padresponsive {
    padding-top: 70px;
  }
}

.bottom-ifrmae {
  margin-bottom: -17% !important;
  @media (max-width: 640px) {
    margin-bottom: -22% !important;
  }
}
.pad-mini {
  padding: 9px !important;
  @media (max-width: 640px) {
    padding: 7px !important;
  }
}

.bgprimary {
  background: var(--qtheme-color-primary) !important;
}
.bgsecondary {
  background: var(--qtheme-color-secondary) !important;
}
.textprimary {
  color: var(--qtheme-color-primary) !important;
}

.hoverunder:hover {
  text-decoration: underline !important;
}
.line-clamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bg-glass {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(1px) !important;
}
.align-center {
  align-items: center !important;
}
.counter-cs {
  display: flex;
  align-items: center;
  border: 1px solid #8080804a;
  border-radius: 6px;
  justify-content: center;
}
.btn-square {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 1px solid #444 !important;
  color: #444 !important;
}

.flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mr-12px {
  margin-right: 12px;
}
.sidebarclass.sidebarfixcls {
  z-index: 999999999 !important;
}
.swipe-height {
  height: 600px !important;
}
.el-carousel__mask {
  display: block !important;
}
.bordb {
  border-bottom: 1px solid #ececec !important;
}
.bordb2 {
  border-bottom: 1px solid #111 !important;
}
@media (max-width: 640px) {
  .nav-pad {
    padding: 10px !important;
  }
  .mobile {
    display: hidden !important;
  }
  .swip .el-carousel__container {
    height: 100px !important;
  }
}
.braek {
  word-break: break-word !important;
}
.dropdown-item:active {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item:focus {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item:hover {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}

.dropdown-item a:active {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item a:focus {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item a:hover {
  background-color: var(--qtheme-color-light) !important;
  color: #222 !important;
}
.dropdown-item {
  color: #444 !important;
}
.car-height .el-carousel__container {
  height: 600px !important;
  @media (max-width: 640px) {
    height: 450px !important;
  }
}
.borderltb {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  @media (max-width: 640px) {
    border-radius: 12px;
  }
}
.opactid {
  opacity: 0 !important;
}
.height-privacy {
  height: 350px !important;
  @media (max-width: 640px) {
    height: 290px !important;
  }
}

.mini-top {
  margin-top: 12px !important;
}
.list {
  list-style-type: disc !important;
  margin-left: 20px !important;
}
.bg-light-success {
  background-color: #c0ffbb !important;
}
.bg-light-danger {
  background-color: #ffcfd5 !important;
}
.max-min {
  min-width: 0px !important;
}
.input {
  border: 1px solid #a6adb778;
  font-size: 14px;
  border-radius: 6px;
  padding: 0.5rem;
}
.input:focus {
  outline: none !important;
}
.elshadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.phoneinput .form-control {
  width: 100% !important;
  padding: 0.5rem 14px 0.5rem 50px !important;
}
.react-tel-input .country-list {
  width: 350px !important;
}
.verifyemail {
  background: #6a2c70;
  padding: 3px 10px;
  border-radius: 5px;
  color: #fff;
  top: 16px;
  cursor: pointer;
}
.resendotp-t [type="text"] {
  border: 1px solid #a6adb778;
  font-size: 14px;
  border-radius: 6px;
  width: 40px !important;
  height: 40px !important;
}

@media only screen and (min-width: 600px) {
  .eventsNav {
    width: 65% !important;
    background: #fff !important;
    position: sticky !important;
    top: 69px !important;
    padding: 20px !important;
    margin: auto !important;
    left: 0% !important;
  }
}

.new-drop .dropdown-menu {
  position: absolute !important;
  top: 45px !important;
  height: none !important;
  background: #fff !important;
  left: -45px !important;
}
.rounded-cancel {
  border-radius: 0px !important;
}
.mega-menu .dropdown-menu {
  position: fixed !important;
  left: 0px !important;
  top: 69px !important;
  width: 100% !important;
  height: 100vh !important;
  background: #11111145 !important;
  opacity: 2 !important;
  opacity: filter !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
a#basic-nav-dropdown {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.borderb {
  border-bottom: 1px solid #ececec !important;
}

.clr-black {
  color: #000;
}

// .hidden {
//   display: block;
//   @media (max-width: 641px) {
//     display: none !important;
//   }
// }
.cust-collapse {
  width: "100%";
  padding: 12px 20px 12px 12px;
  margin: 0px 0px 24px 0px;
  background: white;
  box-shadow: 0 2.97px 14.85px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
@media (max-width: 640px) {
  .wid-auto {
    width: auto !important;
  }
}
.old-drop .dropdown-toggle::after {
  display: none !important;
}
.accord-header .accordion-header button {
  background-color: #fff !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.el-tabs__item.is-active {
  border-bottom: #1c224b !important;
  color: #1c224b !important;
}

.el-tabs__active-bar {
  background: #1c224b !important;
}
.el-main .el-tabs__header {
  border-bottom: none !important;
}
.placess .el-input__inner {
  padding-left: 30px !important;
  padding-right: 21px !important;
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}
.el-input__inner {
  padding: 21px !important;
}
.znotifiaction {
  z-index: 99999999 !important;
  background: #00000082 !important;
}
.bg-notifydanger {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  background-color: red !important;
}

.el-picker-panel.el-date-picker {
  z-index: 99999999999 !important;
}
.el-date-editor.el-input {
  width: 100% !important;
}
.lh22px {
  line-height: 22px !important;
}
.c-lightgrey {
  background: #f4f5f6a5;
}
.outlineInput:focus {
  border-color: var(--qtheme-color-primary) !important;
}

.mob-full {
  @media (max-width: 575px) {
    width: 100% !important;
  }
}

.mob-flexdirection {
  @media (max-width: 768px) {
    flex-direction: column-reverse !important;
  }
}
.min-w2rem {
  @media (min-width: 576px) {
    margin-top: 5rem !important;
  }
}
.loader-set {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  background: #fff;
  z-index: 9999999;
  fill: var(--qtheme-color-primary) !important;
}
